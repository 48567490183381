<template>
  <div id="ada">
    <Widget v-if='HEALTHY'
      :sender="SENDER" 
      :open="false" 
      :bannerDismissed="bannerDismissed" 
      :botBaseUrl="BOT_BASE_URL"
      :STATIC_URL="STATIC_URL"
      :IE="IE"
      :GREETING="GREETING"
    ></Widget>
  </div>
</template>

<script>
import Widget from './components/Widget.vue'
export default {
  name: 'App',
  components: {
    Widget
  },
  data() {
    return {
      // COOKIENAME: 'ahrq_ada_client_sender',
      HEALTHY: false,
      COOKIENAME: 'AHRQ_CHAT_CONVERSATION_ID',
      SENDER: '',
      IE: false,
      show: false,
      bannerDismissed: false,
      STATIC_URL: '',
      BOT_BASE_URL: '',
      GREETING: [],
      MODES: {
        'localhost': 'LOCAL',
        'ahrq-dev-app-06.ahrq.local': 'DEV',
        'search-stage-862181509.us-east-1.elb.amazonaws.com': 'ELB',
        'ahrq-ahrq-dev-1293515422.us-east-1.elb.amazonaws.com': 'TEST',
        'www.ahrq.gov': 'PROD',
        'dexi-chatbot.ahrq.local': 'SAME',
        'searchapp-stage.ahrq.local': 'DEV',
        'search-stage.ahrq.local': 'DEV',
        'search-stage-01.ahrq.local': 'DEV'
      },
      IPINFO: {},
      AHRQ_CHAT_USER_ID: '',
      CONFIGS: {
        PROD: {
          STATIC_URL: 'https://dexi.ahrq.gov',
          BOT_BASE_URL: 'https://dexi.ahrq.gov',
        },
        DEV: {
          STATIC_URL: 'http://search-stage-01.ahrq.local:58986',
          BOT_BASE_URL: 'http://search-stage-01.ahrq.local:58986',
        },
        TEST: {
          STATIC_URL: 'http://search-stage-862181509.us-east-1.elb.amazonaws.com',
          BOT_BASE_URL: 'http://search-stage-862181509.us-east-1.elb.amazonaws.com',
        },
        LOCAL: {
          STATIC_URL: 'http://localhost:8080',
          BOT_BASE_URL: 'http://localhost:8080',
        },
        ELB: {
          STATIC_URL: 'http://search-stage-862181509.us-east-1.elb.amazonaws.com',
          BOT_BASE_URL: 'http://search-stage-862181509.us-east-1.elb.amazonaws.com',
        },
        SAME: {
          STATIC_URL: 'http://dexi-chatbot.ahrq.local',
          BOT_BASE_URL: 'http://dexi-chatbot.ahrq.local',
        },
      }
    }
  },
  created() {
    let mode = this.MODES[location.hostname] || 'PROD'
    console.log("Mode: " + mode)
    let config = this.CONFIGS[mode]
    if(navigator.userAgent.match(/Trident.*rv:11\./)) {
        this.IE = true;
        console.log("Internet Explorer 11 detected.")
    }
    this.STATIC_URL = config.STATIC_URL
    this.BOT_BASE_URL = config.BOT_BASE_URL
    this.pingServer()
    let that = this
    let messages
    const url = `${this.BOT_BASE_URL}/webhooks/rest/user/greeting`
    this.$http.get(url, {params: {sender: this.getSender(), user: this.getUserID(), senderhost: location.hostname}}).then(response => {
        messages = response.data.map(message => {
            return {recipient_id: that.sender, text: message, from: "response"}
        })
        that.GREETING = messages
    }).catch(error => {
        console.log(error.message)
        messages = [
            {recipient_id: this.sender, text: "Hello! I'm Ada.", from: "response"},
            {recipient_id: this.sender, text: "Ask me a question and I will do my best to answer, or ask me 'What can you do?' to view some common questions.", from: "response"},
            {recipient_id: this.sender, text: "Do you have any questions for me?", from: "response"},
        ]
        that.GREETING = messages
    })
  },
  mounted() {
    
  },
  methods: {
    pingServer() {
      // Only show chatbot if the server is reachable
      const url = `${this.BOT_BASE_URL}/webhooks/rest`
      this.$http.get(url).then((response) => {
        if (response.status === 200) {
          this.HEALTHY = true
          // Get or set sender id using session storage.
          this.SENDER = this.getSender()
          this.AHRQ_CHAT_USER_ID = this.getUserID()
        }
        
      }).catch(error => {
        console.log(error.message)
      })
    },
    generateSessionId() {
      return this.guidGenerator()
    },
    guidGenerator() {
      const S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
      };
      return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  },
    getSender() {
      let session_id = sessionStorage.getItem("AHRQ_CHAT_CONVERSATION_ID")
      if (session_id == undefined || session_id == null) {
        session_id = this.generateSessionId()
        sessionStorage.setItem("AHRQ_CHAT_CONVERSATION_ID", session_id)
      }
      return session_id
    },
    getUserID() {
      let sender_id = localStorage.getItem("AHRQ_CHAT_USER_ID")
      if (sender_id == undefined || sender_id == null) {
        sender_id = this.guidGenerator()
        localStorage.setItem("AHRQ_CHAT_USER_ID", sender_id)
      }
      return sender_id
    },
  },
}
</script>

<style>
#ada {
  all: unset;
  font-family: "Public Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
}
</style>
