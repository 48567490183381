<template>
    <strong>
        <span class="ellipsis-anim">
            <span>&bull; </span>
            <span>&bull; </span>
            <span>&bull;</span>
        </span>
    </strong>
</template>
<script>
export default {
    name: 'Ellipsis'
}
</script>
<style scoped>
/* Ellipsis Animation */
.ellipsis-anim span {
    opacity: 0;
    font-size: 1.5rem;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes ellipsis-dot {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}
</style>