<template>
    <div class="ada-banner">
        <img :src="`${STATIC_URL}/public/static/img/ahrq_avatar.png`" class="ada-banner__avatar" alt="AHRQ digital assistant avatar">
        👋 Hi there! Have any questions? I can help.
        <button
            @click="close"
            class="ada-banner__close"
        >
            <span class="btn-label">Close Banner</span>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="gray" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg> 
        </button>
    </div>
</template>
<script>
export default {
    name: 'Banner',
    props: {
        STATIC_URL: {
            type: String,
        }
    },
    methods: {
        close() {
            this.$emit("closeBanner")
        }
    },
}
</script>

<style scoped>
    .btn-label {
        font-size: 0;
        height: 1px;
        overflow: hidden;
        display: block;
    }
    .ada-banner {
        position: fixed;
        right: 58px;
        bottom: 100px;
        width: 90%;
        max-width: 228px;
        background-color: #fff;
        color: #545769;
        padding: 35px 22px 22px;
        border-radius: 4px;
        box-shadow: 0 5px 22px 0 rgba(0,0,0,.1);
        font-size: 15px;
    }
    .ada-banner__close {
        position: absolute;
        top: 0;
        right: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 5px 10px;
        color: #6d708b;
        font-size: 18px;
        cursor: pointer;
        opacity: .6;
        background-color: transparent;
    }
    .ada-banner:after {
        position: absolute;
        bottom: -30px;
        right: 30px;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 45px 30px 0;
        border-color: transparent #fff transparent transparent;
        filter: drop-shadow(5px 10px 8px rgba(0, 0, 0, .1));
    }
    .ada-banner__avatar {
        position: absolute;
        background-color: #fff;
        width: 43px;
        height: 43px;
        top: 0;
        left: 50%;
        transform: translate(-50%,-40%);
        border-radius: 50%;
        border: solid 1px #bfbfbf;
        overflow: hidden;
    }
    @media only screen and (max-width: 768px) {
        .ada-banner {
            bottom: 130px;
        }
    }
</style>