<template>
    <div class="message-container" v-bind:class="{'not-overflowed': !overflowed(this), 'ie11': IE}" ref="messageArea">
        <Message 
            v-for="(messageArray, index) in messages" 
            :key="`message-${index}`" 
            :messages="messageArray" 
            :from="messageArray[0].from" 
            @clickedButton=clickedButton
            :disablebuttons="index < messages.length - 1"
            :STATIC_URL="STATIC_URL"
        ></Message>
    </div>
</template>
<script>
import Message from './Message.vue'
export default {
    name: 'MessageContainer',
    components: {
        Message,
    },
    props: {
        messages: {
            type: Array,
        },
        STATIC_URL: {
            type: String,
        },
        IE: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    updated() {
        if (this.$refs.messageArea){
            let container = this.$refs.messageArea
            if (container.scrollHeight > container.clientHeight) {
                container.classList.remove('not-overflowed')
            }
            this.scrollToBottomofConversation()
        }
        
    },
    mounted() {
        let container = this.$refs.messageArea
        if (container.scrollHeight > container.clientHeight) {
            container.classList.remove('not-overflowed')
            this.scrollToBottomofConversation()
        }
    },
    methods: {
        overflowed() {
            if (this.$refs.messageArea) {
                let container = this.$refs.messageArea
                return container.scrollHeight > container.clientHeight
            }
            return false
        },
        clickedButton(buttonObject) {
            this.$emit('clickedButton', buttonObject)
        },

        scrollToBottomofConversation() {
            let container = this.$refs.messageArea
            if (container == null) return
            container.scrollTop = container.scrollHeight
        },
    },
}
</script>
<style>
    .message-container {
        background-color: #fff;
        height: 50vh;
        max-height: 425px;
        overflow-y: hidden;
        padding-top: 10px;
        padding-right: 15px;
        transition: opacity 0.2s 1s ease;
    }
    .message-container.ie11 {
        padding-right: 17px;
    }

    .message-container:hover {
        overflow-y: auto;
        padding-right: 0px;
        
    }
    .message-container::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .message-container::-webkit-scrollbar:vertical {
        width: 15px;
    }

    .message-container::-webkit-scrollbar:horizontal {
        height: 15px;
    }

    .message-container::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, .5);
    }

    .message-container::-webkit-scrollbar-track { 
        background-color: #fff; 
        border-radius: 8px; 
    } 
    .message-container.not-overflowed {
        padding-right: 15px;
    }
    .message-container.not-overflowed:hover {
        padding-right: 15px;
    }
    .message-container.not-overflowed.ie11 {
        padding-right: 17px;
    }
    .message-container.not-overflowed.ie11:hover {
        padding-right: 17px;
    }
    .message-text > ul {
        padding-left: 15px;
    }
    @media only screen and (max-width: 768px) {
        .message-container {
            height: 100%;
            max-height: calc(100% - 135px);
        }
    }
</style>