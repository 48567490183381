<template>
    <div class="widget-container">
        <transition name="slide-fade">
            <div v-if="chatOpen" class="conversation-container">
                <Header 
                    @toggle="toggleChat"
                    :title="title"
                    :STATIC_URL="STATIC_URL"
                ></Header>
                
                <MessageContainer 
                    :messages="messages"
                    :STATIC_URL="STATIC_URL"
                    :IE="IE"
                    @clickedButton='clickedButton'
                    @scrollToBottomofConversation='scrollToBottomofConversation'
                />
                <InputArea @usermessage='usermessage'></InputArea>
            </div>
        </transition>
        <Fab :STATIC_URL="STATIC_URL" @toggle="toggleChat"></Fab>
        <Banner :STATIC_URL="STATIC_URL" v-if="showBanner" @closeBanner="closeBanner"/>
    </div>
</template>
<script>
import _ from 'lodash';
import MessageContainer from './MessageContainer.vue'
import Fab from './Fab.vue'
import Header from './Header.vue'
import InputArea from './InputArea.vue'
import Banner from './Banner.vue'
export default {
    name: 'Widget',
    components: {
        Fab,
        Header,
        InputArea,
        MessageContainer,
        Banner,
    },
    props: {
        sender: {
            type: String
        },
        bannerDismissed: {
            type: Boolean,
            default: false,
        },
        botBaseUrl: {
            type: String
        },
        STATIC_URL: {
            type: String
        },
        IE: {
            type: Boolean,
            default: false,
        },
        GREETING: {
            type: Array,
        }
    },
    data() {
        return {
            title: '<strong>A</strong><span style="font-size: 0.75em;">HRQ</span> <strong>D</strong><span style="font-size: 0.75em;">igital</span> <strong>A</strong><span style="font-size: 0.75em;">ssistant</span>',
            showBanner: false,
            isMounted: false,
            chatOpen: false,
            newConversation: true,
            messages: [],
            userMessage: '',
            botResponse: null,
            senderMap: {user: 'client', bot: 'response'},
            errorMessage: "🤔 I'm sorry. I seem to be having issues connecting.",
            defaultMessage: `Hello, I'm the AHRQ bot. What can I help you with today?`,
            userInteracted: false,
        }
    },
    created() {
        let bannerDismissed = JSON.parse(sessionStorage.getItem('AHRQ_CHAT_BANNER_DISMISSED')) || false
        this.showBanner = !bannerDismissed
        let conversation = JSON.parse(sessionStorage.getItem('AHRQ_CHAT_CONVERSATION'))
        if (conversation != undefined && conversation != null) {
            this.messages = conversation
            this.newConversation = false
        } else { // new conversation
            this.newConversation = true
        }
    },
    updated() {
        if (this.$refs.messageArea){
            this.scrollToBottomofConversation()
        }
        sessionStorage.setItem('AHRQ_CHAT_CONVERSATION', JSON.stringify(this.messages))
        
    },
    mounted() {
        this.scrollToBottomofConversation()
        this.isMounted = true;
    },
    methods: {
        initializeConversation() {
            let messages = this.GREETING || [
                {recipient_id: this.sender, text: "Hello! I'm Ada.", from: "response"},
                {recipient_id: this.sender, text: "Ask me a question and I will do my best to answer, or ask me 'What can you do?' to view some common questions.", from: "response"},
                {recipient_id: this.sender, text: "Do you have any questions for me?", from: "response"},
            ]
            this.newConversation = false
            this.delayMessages(messages, 0, 1000)        
        },
        lastBotMessageIndex() {
            if (this.messages.length <= 1) return 0
            let found = false
            for (var i = this.messages.length - 1; i >= 0; i--) {
                let messageArray = this.messages[i]
                messageArray.forEach(function(msg) {
                    if (msg.from == 'response' && msg.text != '&hellip;') {
                        found = true
                    }
                })
                if (found) break
            }
            return found?i:-1
        },
        delayMessages(messages, messageIndex, delay) {
            if (this.messages.length < messageIndex + 1) {
                this.messages.push([messages.shift()])
            }
            this.createEllipsisMessage()
            let that = this
            _.each(messages, function(message, i) {
                setTimeout(function () {
                    that.removeEllipsisMessage()
                    if (that.messages.length === 0) {
                        that.messages.push([message])
                    } else {
                        that.messages[messageIndex].push(message)
                    }
                    if (i < messages.length - 1) {
                        that.createEllipsisMessage()
                    }
                }, delay * (i + 1));
            });   
        },
        closeBanner() {
            this.showBanner = false
            sessionStorage.setItem('AHRQ_CHAT_BANNER_DISMISSED', 'true')
        },
        toggleChat() {
            this.chatOpen = !this.chatOpen
            this.showBanner = false
            if (this.chatOpen && this.newConversation) {
                this.initializeConversation()
                this.closeBanner()
            }
        },
        botMessage(payload) {
            this.createEllipsisMessage()
            this.$http.post(this.botBaseUrl + '/webhooks/rest/webhook', {
                sender: this.sender,
                message: payload,
            }).then(response => {
                // Delete the last ellipsis message
                this.removeEllipsisMessage()
                if (response.data.length > 1) {
                    let messages = []
                    let messageIndex = this.lastBotMessageIndex() + 2
                    response.data.forEach(message => {
                        let newMessage = {...message, from: 'response'}
                        newMessage.text = this.convertMarkdown(newMessage.text)
                        messages.push(newMessage)
                    })
                    this.delayMessages(messages, messageIndex, 1000)
                } else {
                    let newMessage = {...response.data[0], from: 'response'}
                    newMessage.text = this.convertMarkdown(newMessage.text)
                    this.messages.push([newMessage])
                }
            }).catch(error => {
                console.log(error.message)
                // window.messages = this.messages
                this.removeEllipsisMessage()
                this.messages.push([{recipient_id: this.sender, text: this.errorMessage, from: 'response'}])
            })
        },

        clickedButton(buttonObject) {
            let messageObject = {text: buttonObject.title, payload: buttonObject.payload}
            this.usermessage(messageObject)
        },
        convertMarkdown(text) {
            text = text || ''
            if (text.includes('\n * ')) {
                // renderInline doesn't render * properly
                return this.$md.render(text)
            }
            return this.$md.renderInline(text)
        },
        throttledMessage: _.throttle( function(message) {
            this.removeEllipsisMessage()
            this.messages.push([message])
            
        }, 1500),
        scrollToBottomofConversation() {
            let container = this.$refs.messageArea
            if (container == null) return
            container.scrollTop = container.scrollHeight
        },
        usermessage(messageObject) {
            if (!this.userInteracted) {
                this.userInteracted = true
            }
            messageObject['from'] = 'client'
            this.messages.push([messageObject])
            this.botMessage(messageObject.payload)
        },
        createEllipsisMessage() {
            let messageObject = [{recipient_id: this.sender, text: '&hellip;', from: 'response'}]
            this.messages.push(messageObject)
        },
        removeEllipsisMessage() {
            let ix = this.messages.slice().findIndex(x=>x[0]['text'] === '&hellip;')
            if (ix > -1) this.messages.splice(ix, 1)
        }
    },
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
.widget-container {
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    position: fixed;
    right: 0;
    width: auto;
    z-index: 9999;
    align-items: flex-end;
    justify-content: flex-end;
}

.conversation-container {
    border-radius: 10px;
    box-shadow: 0 2px 10px 1px #b5b5b5;
    overflow: hidden;
    width: 370px;
    -webkit-box-shadow: 0 1px 8px 0 rgba(122,107,107,.22);
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .conversation-container {
    position:fixed;
    padding:0;
    margin:0;
    z-index: 1000;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .widget-container {
      margin: 0 20px 55px 0;
  }
}
</style>