<template>
    <button class="fab fab-assertive" @click="toggleChat()" aria-label="AHRQ Digital Assistant">
        <span class="btn-label">AHRQ Digital Assistant</span>
        <div class="fab-icon-container">
          <img :src="`${STATIC_URL}/public/static/img/message-md.png`" alt="AHRQ Digital Assistant message icon." />
        </div>
    </button>
</template>
<script>
export default {
    name: 'Fab',
    props: {
      STATIC_URL: {
          type: String
      }
    },
    components: {
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        toggleChat() {
            this.$emit("toggle")
        }
    }
}
</script>
<style scoped>
.btn-label {
    font-size: 0;
    height: 1px;
    overflow: hidden;
    display: block;
}
.fab-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}
.widget-container {
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    position: fixed;
    right: 0;
    width: auto;
    z-index: 9999;
    align-items: flex-end;
    justify-content: flex-end;
}
@media only screen and (max-device-width: 480px) {
    .outer-container {
        position: fixed; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
        overflow-y: scroll;
    }
    .chat-container {
        /* display: block; Hidden by default */
        margin: 0 !important;
        width: 100vw !important;
        height: 100vh !important; /* Full height (cover the whole page) */
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
        overflow-y: scroll;
    }
}
.chat-container {
    /* https://stackoverflow.com/questions/36316862/how-to-get-a-div-to-slide-up-from-the-bottom-of-the-page-using-css/36317392 */
    display: flex;
    /* font-family: "Public Sans", sans-serif; */
    position: absolute;
    width: 40vh;
    height: 55vh;
    bottom: 7rem;
    /* top: 0; */
    overflow: hidden;
    right: 3rem;
    border: 3px solid green;
    transition: all 1s;
}
.chat-container.close {
    /* top: 100%; */
    height: 0;
    visibility: hidden;
}

.fab-wrapper {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
}
.fab {
    position: relative;
    margin-top: 10px;
    width: 4rem;
    height: 4rem;
    background: #005b94;
    /* outline: none; */
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    text-align: center;
    font-size: 32px;
    z-index: 999;
    border-radius: 50%;
    transition: 0.2s opacity ease-in-out;
    -webkit-transition: 0.2s opacity ease-in-out;
    border: 0;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18), 
                0px 4px 12px -7px rgba(0, 0, 0, 0.15);
}

.fab:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  background: #aaa;
  border-radius: 100%;
  opacity: .6;
  transform: scale(0); }

@keyframes ripple {
  0% {
    transform: scale(0); }
  20% {
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1); } }

.fab:not(:active):after {
  /* animation: ripple 3s ease-out; */
  animation: ripple 1s ease-out;
}

/* fixes initial animation run, without user input, on page load.
 */
.fab:after {
  visibility: hidden;
}

.fab:focus:after {
  visibility: visible;
}

.fab.fab-light {
  color: #fff;
  background: #fff;
}

.fab.fab-stable {
  color: #fff;
  background: #f8f8f8;
}

.fab.fab-positive {
  color: #fff;
  background: #1b69a2;
}

.fab.fab-calm {
  color: #fff;
  background: #11c1f3;
}

.fab.fab-assertive {
  color: #fff;
  /* background: #bf1e2e; */
}

.fab.fab-balanced {
  color: #fff;
  background: #33cd5f;
}

.fab.fab-energized {
  color: #fff;
  background: #ffc900;
}

.fab.fab-royal {
  color: #fff;
  background: #886aea;
}

.fab.fab-dark {
  color: #fff;
  background: #444;
}

/*----------------*/
.fab.fab-light:after {
  background: #e6e6e6;
}

.fab.fab-stable:after {
  background: #dfdfdf;
}

.fab.fab-positive:after {
  background: #144d76;
}

.fab.fab-calm:after {
  background: #0a9dc7;
}

.fab.fab-assertive:after {
  /* background: #931723; */
  background: #144d76;
}

.fab.fab-balanced:after {
  background: #28a54c;
}

.fab.fab-energized:after {
  background: #cca100;
}

.fab.fab-royal:after {
  background: #643de4;
}

.fab.fab-dark:after {
  background: #2b2b2b;
}
</style>