import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

let MarkdownIt = require('markdown-it'),
    md = new MarkdownIt({breaks: true, html: true});
// Remember old renderer, if overridden, or proxy to default renderer
var defaultRender = md.renderer.rules.link_open || function(tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // If you are sure other plugins can't add `target` - drop check below
  var aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$md = md
new Vue({
  render: h => h(App),
}).$mount('#ada')
