<template>
    <div class="chat-input-area">
        <input 
            id="chatInput" 
            placeholder="Ask a question..."
            type="text"
            v-model="userDisplayText"
            @keydown.enter="sendMessage"
            ref="chatInput"
            autocomplete="off"
        >
        <button class="chat-send-button" v-on:click="sendMessage">
            <span class="btn-label">Send Message</span>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="grey" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
            </svg>
        </button>
    </div>
</template>
<script>
export default {
    name: 'InputArea',
    data() {
        return {
            userDisplayText: '',
            userMessage: '',
        }
    },
    mounted() {
        this.$refs.chatInput.focus()
    },
    methods: {
        sendMessage() {
            if (this.userDisplayText.trim().length === 0) {
                return
            }
            if (!this.userMessage) {
                this.userMessage = this.userDisplayText
            }
            // Create a message object with correct payload, while updating the displayText
            let messageObj = {
                payload: this.userDisplayText,
                text: this.userDisplayText
            }

            this.$emit('usermessage', messageObj)
            this.userDisplayText = ''
            this.userMessage = ''
        }
    },
}
</script>
<style scoped>
.btn-label {
    font-size: 0;
    height: 1px;
    overflow: hidden;
    display: block;
}
#chatInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4D5060;
  opacity: 1; /* Firefox */
}

#chatInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #4D5060;
}
.chat-input-area {
    margin-top: auto;
    height: 55px;
    padding-left: 12px;
    padding-right: 0px;
    background-color: rgb(240, 240, 240, 1);
    display: flex;
    align-items: stretch;
}
#chatInput {
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: rgb(240, 240, 240, 1);
    flex: 2 1 auto;
}
#chatInput:focus {
    outline: none;
}

.chat-send-button {
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 16px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
    cursor: pointer; /* Mouse pointer on hover */

}

.chat-send-button:focus {
    /* outline: none; */
}

input[type="text"]
{
    font-size:16px;
    color: rgba(0, 0, 0, 0.87);
}
</style>