<template>
    <div class="group-message" :class="`from-${from}`">
        <template v-if="from=='response' && messages[0].text == '&hellip;'">
            <div class="message with-avatar">
                <img v-if="from==='response'" :src="`${STATIC_URL}/public/static/img/ahrq.jpg`" class="avatar" alt="AHRQ digital assistant avatar">
                <div :class="from" style="padding-top: 2px; padding-bottom: 0px;">
                    <Ellipsis></Ellipsis>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="message with-avatar" v-for="(message, index) in messages" :key="`message-${index}`">
                <img v-if="from==='response'" :src="`${STATIC_URL}/public/static/img/ahrq.jpg`" class="avatar" alt="AHRQ digital assistant avatar">
                <div v-if="!('buttons' in message)" :class="from">
                    <div class="message-text" v-html="message.text">
                    </div>
                </div>
                <div v-else class="quickReplies-container">
                    <div :class="from">
                        <div class="message-text"  v-html="message.text"></div>
                    </div>
                    <div class="replies">
                        <button 
                            v-for="(button, ix) in message.buttons" 
                            :key="`message-button-${index}-${ix}`" 
                            class="reply" 
                            @click="clicked(button)"
                            :disabled="disablebuttons"
                        >{{ button.title }}</button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Ellipsis from './Ellipsis.vue'
export default {
    name: 'Message',
    components: {
        Ellipsis
    },
    props: {
        messages: {
            type: Array
        },
        from: {
            type: String,
            default: 'client'
        },
        disablebuttons: {
            type: Boolean,
            default: false
        },
        STATIC_URL: {
            type: String,
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        clicked(btnObj) {
            this.$emit('clickedButton', btnObj)
        }
    },
}
</script>
<style scoped>
.quickReplies-container {
    width: 100%;
}
.conversation-container .message {
    margin: 10px;
    display: flex;
    font-size: 14px;
    flex-wrap: wrap;
}

.client {
    background-color: #0084ff;
    color: #fff;
}

.conversation-container .client {
    background-color: #005b94;
    color: #fff;
    border-radius: 1rem;
    padding: 7px 15px;
    max-width: 215px;
    text-align: left;
    margin-left: auto;
    overflow-wrap: break-word;
}

.group-message.from-response .message:first-child:not(:only-child) .response {
    border-radius: 0 15px 15px 0;
}

.group-message.from-response .message:not(:first-child):not(:last-child):not(:last-child) .response {
    border-radius: 0 15px 15px 0;
}

.conversation-container .message-text {
    margin: 0;
}

.response {
    background-color: #f4f7f9;
    color: #617287;
}

.conversation-container .response {
    background-color: #e9eaeb;
    color: #000;
    border-radius: 0 1rem 1rem 1rem;
    padding: 7px 15px;
    max-width: 215px;
    text-align: left;
    overflow-wrap: break-word;
}
.group-message.from-response .message:not(:first-child):not(:only-child) .avatar {
    display: none;
}

.group-message.from-response .message:first-child:only-child .response {
    border-radius: 1rem 1rem 1rem 1rem;
}

.group-message.from-response .message:first-child:not(:only-child) .response {
    border-radius: 1rem 1rem 1rem 0rem;
}
.group-message.from-response .message:not(:first-child):not(:only-child).with-avatar {
    margin-left: 45px!important;
}

.conversation-container .avatar {
    width: 30px!important;
    height: 30px!important;
    border-radius: 100%;
    margin-right: 3px;
    position: relative;
    bottom: 5px;
    border: solid 1px #bfbfbf;
}

img {
    border: 0;
    max-width: 100%;
}

.group-message.from-response .message:first-child:not(:only-child) {
    margin-bottom: 2px;
}

.group-message.from-response .message:last-child:not(:only-child) {
    margin-top: 2px;
}
.group-message.from-response .message:not(:first-child):not(:only-child) .avatar {
    display: none;
}

.group-message.from-response .message:not(:first-child):not(:last-child):not(:last-child) {
    margin-top: 2px!important;
    margin-bottom: 2px!important;
}
.group-message.from-response .message:not(:first-child):not(:only-child).with-avatar {
    margin-left: 45px!important;
}


/* Ellipsis Animation */

.ellipsis-anim span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes ellipsis-dot {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.conversation-container .replies {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    font-size: 14px;
}

.conversation-container .reply {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border: 1px solid #005b94;
    border-radius: 15px;
    color: #005b94;
    background-color: #fff;
    padding: 4px 8px;
    min-height: 1.7em;
    max-width: 100%;
    text-align: center;
    cursor: pointer;
    margin: .25em;
}
.conversation-container .reply:disabled {
    border: 1px solid #6c757d;
    color: #6c757d;
    background-color: transparent;
    cursor: not-allowed;
}
</style>