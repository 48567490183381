<template>
    <div class="chat-area-header">
        <div class="avatar">
            <img :src="`${STATIC_URL}/public/static/img/ahrq.jpg`" class="avatar" alt="AHRQ digital assistant avatar">
        </div>
        <div class="header-title">
            <div class="ada-title">Ada</div>
            <div class="ada-subtitle" v-html="title"></div>
        </div>
        <div class="close-btn">
            <button class="chat-exit-button" v-on:click="$emit('toggle')">
                <span class="btn-label">Close Ada Window</span>
                <img :src="`${STATIC_URL}/public/static/img/close-sm.png`" alt="Close chat window icon." />
                <!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="white" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg> -->
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChatHeader',
    props: {
        title: {
            type: String
        },
        STATIC_URL: {
            type: String
        }
    }
}
</script>
<style scoped>
.btn-label {
    font-size: 0;
    height: 1px;
    overflow: hidden;
    display: block;
}
.chat-area-header {
    background-color: #005b94;
    display: flex;
    align-items: center;
    justify-content: center;
    height:70px;
    width:370px;
}
.ada-title {
    font-weight: bold;
}
.ada-subtitle {
    font-size: 0.75em;
}
.header-title {
    color: #fff;
    font-size: 1.25rem;
    flex: 4;
    padding-left: 20px;
}

.close-btn {
    flex: 1;
}

.conversation-container .avatar {
    width: 30px!important;
    height: 30px!important;
    border-radius: 1rem;
}
.chat-area-header .avatar {
    margin-left: 10px;
}
.chat-card-header {
    background-color: #005b94;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    color: white;
}

.chat-header {
    background-color: #005b94;
    height:70px;
    width:370px;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    color: white;
  
}
.chat-exit-button {
    border: none;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    background-color: #005b94;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.chat-exit-button:hover {
    background-color: #20689d;
}
.chat-exit-button:focus {
    /* outline: none; */
}
@media only screen and (max-width: 768px) {
    .chat-area-header {
        width:100%;
    }
}
</style>